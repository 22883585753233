import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-springfield-missouri.png'
import image0 from "../../images/cities/scale-model-of-417-escape-artist-in-springfield-missouri.png"
import image1 from "../../images/cities/scale-model-of-discovery-center-of-springfield-in-springfield-missouri.png"
import image2 from "../../images/cities/scale-model-of-rutledge-wilson-farm-community-park-in-springfield-missouri.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Springfield'
            state='Missouri'
            image={image}
            lat='39.78172130000001'
            lon='-89.65014810000002'
            attractions={ [{"name": "417 Escape Artist", "vicinity": "2147 W Republic Rd, Springfield", "types": ["point_of_interest", "establishment"], "lat": 37.1397115, "lng": -93.32332259999998}, {"name": "Discovery Center of Springfield", "vicinity": "438 E St Louis St, Springfield", "types": ["museum", "point_of_interest", "establishment"], "lat": 37.2085505, "lng": -93.28863849999999}, {"name": "Rutledge-Wilson Farm Community Park", "vicinity": "3825 W Farm Rd 146, Springfield", "types": ["park", "point_of_interest", "establishment"], "lat": 37.191319, "lng": -93.36073399999998}] }
            attractionImages={ {"417 Escape Artist":image0,"Discovery Center of Springfield":image1,"Rutledge-Wilson Farm Community Park":image2,} }
       />);
  }
}